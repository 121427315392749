/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get } from "@/core/config/ApiService";

class Api {
  async get(ref: any): Promise<any> {
    const resp = await get(`api/order/${ref}`);
    return resp.data;
  }
}
export default new Api();
