import { $axios } from "./BaseURL";

const axiosInstances = [$axios];

function setHeaderFormData() {
  axiosInstances.forEach((axios) => {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.post["Accept"] = "application/json";
  });
}

axiosInstances.forEach((axios) => {
  axios.interceptors.response.use(
    function (response) {
      if (response.data.status == false) {
        // Storage.clearToken();
        window.location.reload();
      }
      return response;
    },
    function (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(error);
      }
      if (error.response.status === 401) {
        // Storage.clearToken();
        window.location.reload();
      }

      return error.response;
    }
  );
});

const get = (path: any, params: any = undefined) => {
  setHeaderFormData();
  return $axios.get(path, { params });
};

const post = (path: any, data: any = undefined) => {
  setHeaderFormData();
  return $axios.post(path, data);
};

const postUpload = (path: any, data: any) => {
  setHeaderFormData();
  return $axios.post(path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export { get, post, postUpload };
