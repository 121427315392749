import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import referenceID from "../views/ReferenceID.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/referenceId=:referenceId",
    name: "referenceId",
    component: referenceID,
  },
  {
    path: "/:pathMath(.*)*",
    name: "not-found",
    component: () => import("@/views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
