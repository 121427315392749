<template>
  <div v-if="code?.transactionId" class="text-center">
    <div v-if="code.status == 'pending'">
      <img :src="qrCodeImageUrl" style="width: 260px" alt="QR Code" />
      <div style="color: #ff0000" class="fw-800 fs-13 mb-10">
        ห้ามใช้ QRCode ซ้ำ!
      </div>
      <img
        class="mb-10"
        :src="require(`@/assets/image-promptPay.jpg`)"
        style="width: 220px"
      />
    </div>

    <div class="card" v-if="code.status != 'pending'">
      <div
        :style="{
          borderRadius: '200px',
          height: '200px',
          width: '200px',
          background: code.status === 'completed' ? '#f8faf5' : '#fff6f6',
          margin: '0 auto',
        }"
      >
        <Vue3Lottie
          v-if="code.status == 'completed'"
          :loop="false"
          animationLink="/check.json"
          :height="200"
          :width="200"
        />

        <Vue3Lottie
          v-else
          animationLink="/error.json"
          :height="200"
          :width="200"
        />
      </div>
      <p class="fw-500 fs-16">
        {{
          code.status == "completed"
            ? "Success"
            : capitalizeFirstLetter(code?.status)
        }}
      </p>
    </div>
    <hr />

    <p v-if="code?.transactionId" class="mt-8">
      หมายเลขอ้างอิง: {{ code?.transactionId }}
    </p>

    <p class="fw-700 fs-20 mt-8">
      {{ `${code ? `฿${code?.depositAmount?.priceFormat()}` : ""}` }}
    </p>
    <div
      v-if="code.status == 'pending' && txtExpireDate"
      class="text-danger mt-6 fw-400"
    >
      <p>OR Code หมดอายุ {{ `${txtExpireDate === "Expired." ? "" : "ใน"}` }}</p>
      <p class="fs-13">{{ txtExpireDate }}</p>
      <p>
        {{ `${txtExpireDate === "Expired." ? "" : "กรุณาอย่ารีเฟสหน้านี้"}` }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount } from "vue";

import QRCode from "qrcode";
import ApiService from "@/core/services";
import { useRoute, useRouter } from "vue-router";

import { Vue3Lottie } from "vue3-lottie";

const route = useRoute();
const qrCodeImageUrl = ref("");
const code = ref("") as any;

const txtExpireDate = ref("") as any;
let count = 0;
let intervalId;

const generateQRCode = async () => {
  try {
    const resp = await ApiService.get(route.params.referenceId);
    if (resp.status === "pending") {
      qrCodeImageUrl.value = await QRCode.toDataURL(resp.qrcode);
      code.value = resp;
    } else {
      clearInterval(intervalId);
      code.value = resp;
    }
  } catch (error) {
    console.error("Error fetching the QR code:", error);
    clearInterval(intervalId);
    code.value = { status: "error", message: "Failed to fetch QR code" };
  }
};

const countdown = setInterval(function () {
  const now = new Date().getTime();
  const expireDate = new Date(code.value.expireDate).getTime();
  const distance = expireDate - now;

  if (distance < 0) {
    clearInterval(countdown);
    clearInterval(intervalId);
    txtExpireDate.value = "Expired.";
  } else {
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    txtExpireDate.value = `${formattedMinutes} : ${formattedSeconds}`;
  }
}, 1000);

const capitalizeFirstLetter = (string: any) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

onBeforeMount(async () => {
  generateQRCode();
  intervalId = setInterval(generateQRCode, 10000);
});
</script>
