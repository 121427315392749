import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Style
import "@/assets/style/style.scss";

createApp(App).use(store).use(router).mount("#app");

declare global {
  interface Number {
    priceFormat(): string;
  }
}

// Implement the function on Number prototype
Number.prototype.priceFormat = function (): string {
  return Number(this)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
